
.slick-slider-weconnect .slick-track {
  width: 100% !important;
}
.slick-slider-weconnect .slick-list {
  height:100% !important;
}
.slick-slider-weconnect .slick-slider{
  margin-left: 25%;
}
.slick-slider-weconnect .slick-slide {
  height:100% !important;
  width:auto  !important;
}