/***responsive***/

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.school.animate {
  top: 73%;
  left: 46.5%;
}
.about-us.animate {
  left: 33%;
  top: 85%;
}

.kond-festival.animate {
  left: 81%;
  top: 2%;
}

.slick-initialized .slick-slide .child-element a {
  padding: 10px 15px;
}

.home-mobile {
  display: none;
}

.stage-item {
  position: absolute;
  left: 2%;
  width: 58%;
  top: -22px;
}

.school-item {
  position: absolute;
  left: 21%;
  width: 46%;
  top: -21px;
}

.about-us-item {
  left: 0;
  width: 41%;
  top: 44%;
}
@media (max-width: 1800px) {
  .stage.animate {
    top: 28%;
  }

  .school.animate {
    top: 67%;
  }

  .about-us.animate {
    top: 77%;
  }
}
@media (max-width: 1500px) {
  .stage.animate {
    top: 31%;
  }

  .school.animate {
    top: 68%;
    left: 45.5%;
  }

  .about-us.animate {
    top: 75%;
    left: 30%;
  }

  .kond-festival.animate {
    left: 77%;
    top: 3%;
  }

  .slick-initialized .slick-slide .child-element a {
    padding: 10px 15px;
  }

  .stage-item {
    position: absolute;
    left: 2%;
    width: 58%;
    top: -22px;
  }

  .school-item {
    position: absolute;
    left: 21%;
    width: 46%;
    top: -21px;
  }

  .about-us-item {
    position: absolute;
    left: 0;
    width: 41%;
    top: 47%;
  }
}

@media (max-width: 1400px) {
  .deco1 img,
  .deco2 img {
    width: 80%;
  }

  .stage.animate {
    top: 28%;
  }
  .stage-item {
    width: 58% !important;
    top: -2% !important;
    left: 4% !important;
  }
  .school.animate {
    top: 64%;
    left: 44%;
  }

  .about-us.animate {
    top: 71%;
    left: 30%;
  }

  .school-item {
    top: -6px;
  }

  header {
    padding: 25px 80px;
  }

  .stage-item {
    left: 6%;
    width: 57%;
    top: -82px;
  }

  .stage-item {
    left: 1%;
    width: 60%;
    top: -34px;
  }

  .about-us-item {
    width: 42%;
    top: 42%;
  }
}

@media (max-width: 1350px) {
  .school.animate {
    top: 64%;
    left: 44%;
  }
}

@media (max-width: 1300px) {
  header {
    padding: 25px 60px;
  }

  .stage.animate {
    top: 35%;
    left: 56%;
  }

  .school.animate {
    top: 74%;
    left: 44%;
  }

  .about-us.animate {
    top: 83%;
    left: 30%;
  }
}

@media (max-width: 1250px) {
  .school.animate {
    top: 57%;
    left: 43%;
  }

  .about-us.animate {
    top: 67%;
  }

  .stage.animate {
    top: 27%;
  }
}

@media (max-width: 1200px) {
  .stage-item {
    top: -18px;
  }

  .school-item {
    top: 2px;
  }

  .about-us-item {
    width: 41%;
    top: 38%;
  }

  .main-container-decoration .slick-active.slick-center:before {
    left: 17px;
    height: 118px;
    width: 76px;
    top: 8px;
    background-size: 67%;
  }

  .main-container-decoration .slick-active.slick-center:after {
    right: -6px;
    bottom: -17px;
    height: 106px;
    width: 77px;
    background-size: 67%;
  }

  .stage.animate {
    top: 26%;
    left: 55%;
  }

  .school.animate {
    top: 55%;
  }

  .about-us.animate {
    top: 62%;
  }
}

@media (max-width: 1100px) {
  .stage.animate {
    top: 23%;
    left: 56%;
  }

  .school.animate {
    top: 50%;
  }

  .about-us.animate {
    top: 60%;
  }
}

@media (max-width: 1000px) {
  .menu p {
    font-size: 14px;
  }

  .stage.animate {
    top: 22%;
  }

  .school.animate {
    top: 52%;
  }

  .about-us.animate {
    top: 57%;
  }
}

@media (max-width: 900px) {
  .stage.animate {
    top: 20%;
  }

  .school.animate {
    top: 45%;
  }

  .about-us.animate {
    top: 52%;
  }
}

@media only screen and (device-width: 1024px) {
  .stage.animate {
    top: 32%;
    left: 59%;
  }
  .about-us-item {
    width: 42%;
    top: 32%;
  }
  .school.animate {
    top: 54%;
  }

  .about-us.animate {
    top: 61%;
    left: 32%;
  }
}

@media only screen and (max-width: 991px) {
  footer .social-block {
    width: 100px;
    float: right;
    text-align: revert;
    display: flex;
    margin: 15px 0;
  }
  .main-container.main-container-decoration .center-slider .slick-list {
    height: 100% !important;
  }
  .slick-arrow {
    right: 10px;
    top: 50% !important;
  }
  .mobile-reverse {
    flex-direction: column-reverse;
  }
  .mobile-reverse .main-description-banner {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .lab-mobile-details p {
    font-size: 14px;
  }
  .mobile-pb {
    padding: 0;
  }
  .we-play-container .slider-title {
    display: block;
  }
  .sml-block .lab-mobile-details a {
    padding: 0;
  }
  .sml-block {
    margin: 0 !important;
  }
  .main-container-decoration .the-platform-slider .slick-initialized .slick-slide {
    left: -8px !important;
  }
  .opinion-content.main-container-decoration .slick-dots {
    height: 30px;
    /* object-fit: contain !important; */
    bottom: 0;
  }
  .video-link iframe {
    width: 100%;
    margin: 0 auto;
    height: 280px;
  }
  .we-play-container .we-dots .slick-slide a img {
    height: 625px;
    object-fit: scale-down;
  }
  .container-not-hover .coming-container .slick-slide img {
    height: 584px;
    object-fit: scale-down;
  }
  .we-play-container .opinion-content .slick-slide a img {
    height: 363px;
    object-fit: scale-down;
  }
  .main-container-decoration .coming-container .slick-dots {
    height: 35px;
    position: absolute;
    bottom: 30px;
  }

  .main-container-decoration .we-dots .slick-dots {
    height: 0;
    position: absolute;
    bottom: 50px;
  }
  .white-decoration {
    position: absolute;
    top: -3px;
    z-index: 1;
    display: block;
  }
  .deco1,
  .deco2 {
    display: none !important;
  }
  .slick-list {
    height: 100% !important;
  }
  .section-details > .row {
    margin-top: 6px !important;
  }

  .home {
    display: none;
  }
  .flex-reverse {
    flex-direction: column-reverse;
  }

  .lab-container .title-generic {
    margin-top: 20px;
  }

  .lab-container {
    margin-top: 50px;
  }

  .home-mobile .hamburger-icon {
    left: 0;
  }

  header {
    display: none;
  }

  .mobile-header {
    width: 100%;
    position: fixed !important;
    display: flex;
  }

  .mobile-header a {
    width: 100%;
  }

  .mobile-logo {
    margin: 0 auto;
    /* text-align: center; */
    width: 100px;
    display: flex;
  }

  .line {
    left: 0;
    height: 14px;
  }

  .mobile-menu {
    background: #fffffff5;
    position: fixed;
    z-index: 2;
    width: 100%;
    color: #000;
    top: 0;
    height: 100vh;
    overflow: hidden;
    padding-top: 100px;
  }

  .mobile-menu a {
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .mobile-line {
    display: flex;
    flex-direction: column;
    /* height: 100px; */
    position: relative;
    cursor: pointer;
    padding-bottom: 40px;
  }
  .we-connect-section .main-description-banner {
    max-width: 100%;
    padding: 0;
  }
  .the-platform-slider .slide {
    padding: 0 20px;
    position: relative;
  }
  .the-platform-slider .slider-title {
    display: block;
  }
  #hamburger-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 0;
    width: 75px;
    padding-left: 30px;
    cursor: pointer;
  }

  body {
    overflow: auto !important;
  }

  .home-mobile {
    display: block;
  }

  .photo-by {
    margin: 32px 0 30px !important;
  }

  .main-container-decoration .slick-dots {
    height: 35px;
  }

  .slick-dots {
    height: 43px;
  }

  .main-container {
    margin-top: 0;
  }

  .main-container-decoration .slick-slider {
    margin: 0;
  }

  .main-container-decoration .slick-track {
    padding-top: 30px;
  }

  .main-container-decoration .slick-list {
    padding-top: 8px !important;
    height: auto !important;
    padding-bottom: 15px !important;
  }

  .current-container .main-description-banner {
    max-width: 100%;
    padding-left: 15px !important;
  }

  .next-page {
    margin-top: 40px;
  }

  .subscribe-block button {
    font-size: 12px;
  }

  .slick-active.slick-center:after {
    right: -36px;
    bottom: -26px;
  }

  .slick-active.slick-center:before {
    left: -20px;
    top: -39px;
  }

  .main-description-banner .title-generic {
    padding-top: 15px;
    margin-bottom: 10px;
  }

  .container.footer-inner {
    padding-top: 15px;
  }

  footer {
    padding-top: 0;
  }

  .main-container-decoration {
    padding-bottom: 40px;
  }

  .main-container .breadcrumb {
    width: 100%;
    margin: 0;
  }

  .title {
    max-width: 100%;
  }

  .slick-initialized .slick-slide .child-element > div {
    padding: 0 15px !important;
  }

  .footer-left {
    align-items: baseline;
    flex-direction: column;
  }

  .footer-brand-banner {
    flex-wrap: wrap;
    padding: 13px !important;
  }

  .description-banner {
    max-width: 93%;
    padding: 0;
  }

  .main-container-decoration:after {
    display: none;
  }

  .home-mobile {
    padding-top: 2%;
    display: block;
    width: 100%;
  }

  /*header{*/
  /*  position: fixed;*/
  /*}*/
  .home-mobile .brand-logo img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }

  footer h2 {
    margin-bottom: 4px;
  }

  .footer-box {
    margin-top: 30px;
  }

  .navbar-brand.animate {
    left: 10px;
  }

  .header {
    padding: 15px;
  }
}

.modal-dialog {
  max-width: 900px !important;
}

.close span {
  color: #707070;
  font-weight: normal;
  margin-right: 8px;
}

/*
.modal-slider .slick-slide {
    margin-right: 5px;
    margin-left: 5px;
}*/

@media (max-width: 1280px) {
  .modal-dialog {
    max-width: 700px !important;
  }

  .footer-panel {
    padding: 0 30px 40px;
  }
}

@media (min-width: 576px) {
  .modal-body .slick-slide img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }

  .modal-body .slick-track {
    padding: 0 !important;
  }
}
.mobile-stage-inner {
  display: none;
}
