.button_language{
    background:none;
    border:none; 
    font-size: 12px;
    margin-top: 12px;
    width: 60px;
}
.home-mobile-lang{
    background: none;
    border: none;
    font-size: 14px;
    margin-top: 0;
    position: absolute;
    left: 23px;
    top: 40px;
}
@media only screen and (max-width: 767px) {
    .button_language{
        display: none;
    }
    .button_language_mobile{
        display: block;
    }
}
.button_language_mobile {
    right: 53px;
    background: none;
    border: none;
    font-size: 14px;
    margin-top: 0;
    position: absolute;
    left: auto;
    top: 40px;
}