.slick-list {
  height: 500px;
}

@media (max-width: 576px) {
  .img-no-stretch {
    object-fit: contain;
    margin: 0;
    width: 75% !important;
  }
}



.slider-title {
  top: 0;
}

.sml-slider {
  max-width: 900px;
  margin: 0 auto;
}

.slide:hover .slider-title {
  display: block;
  position: absolute;
  z-index: 6;
  left: 0;
  top: -40px;
}


.deco1 {
  margin-top: -5%;
}

.we-play-container .slick-slide a img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.main-container-decoration .slick-slider {
  width: 95%;
  margin: 10px auto 0;
  background: transparent;
}
.we-play-container .slick-slide .deco1,
.we-play-container .slick-slide .deco2 {
  width: 20%;
}

.we-play-container .slick-slide .deco2 {
  bottom: -7%;
}

.main-container-decoration.we-play-container .slick-list {
  width: 100% !important;
}
.we-play-container .center-slider {
  max-width: 90% !important;
  margin: 0 auto;
}

.we-play-container .slick-slide {
  margin: 0 !important;
}

.we-play-container .slick-next {
  right: -30px;
}

.we-play-container .slick-prev {
  left: -22px;
}
.we-play-container .slick-arrow {
  top: 44%;
}

.we-play-container .main-container-decoration .slick-list {
  padding: 0 !important;
  width: 100%;
  margin: 0 auto !important;
}

/* .we-play-container .slick-track {
  left: 7px !important;
} */
/*.we-play-container  .slick-slide a img {*/
/*    height: 340px;*/
/*}*/
@media (max-width: 1024px) {
  .we-play-container .slick-prev,
  .we-play-container .slick-next {
    display: none !important;
  }
}
@media (max-width: 1300px) {
  .we-play-container .center-slider {
    max-width: 1000px !important;
  }
  .we-play-container .slick-slide a img {
    height: 225px;
  }
  .we-play-container .center-slider {
    max-width: 100% !important;
  }
  .we-play-container .center-slider {
    max-width: 100% !important;
  }
  .we-play-container .slick-slide a img {
    height: auto;
  }
}

.container-not-hover .slide:hover {
  transform: inherit;
}
.container-not-hover .slick-slide img {
  height: auto;
}
.container-not-hover .center-slider .slick-arrow {
  top: 55%;
}

.bg_transparent {
  background-color: transparent;
}
