.brand-logo.item1:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  color: #fff;
}

.brand-logo.item2:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  color: #fff;
}

nav {
  position: fixed;
  top: 100px;
}

.brand-logo img {
  height: 100%;
  width: 767px;
}

.filler {
  height: 1000px;
}

.filler p {
  padding-top: 350px;
}
.menu-mobile-item {
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: center;
}
.menu-mobile-item span {
  text-transform: uppercase;
  padding-bottom: 10px;
}
.mobile-stage-inner,
.mobile-school-inner,
.mobile-kond-inner,
.mobile-we-inner {
  margin: 40px 0 0 40px;
  z-index: 22;
}
/* Anything with the parallax-parent class acts as the "mask" for the parallax
     their children are automagically scaled and repositioned*/
.parallax-parent {
  height: 100vh;
  overflow: hidden;
}

.parallax-parent > * {
  background-size: cover;
  background-position: center center;
  height: 200%;
  position: relative;
  top: -100%;
}

/* Mobile devices typically only report scrolling events AFTER they finish, which  can lead to jittery scrolling. By wrapping the page in a container with overflow: scroll set, and setting that container in the ScrollMagic Controller, events are updated on the fly.
  Note that this page uses huge images, and mobile optimizations will still cause jitter because of that. with simple backgrounds it'll work as intended. probably.*/

#scroll-container {
  overflow: scroll;
  /* hide the scrollbars by making the child slightly bigger...*/
  height: 105vh;
  width: 105vw;
}

.scroll-parent {
  /* ...and the parent hide overflows */
  height: 100vh;
  overflow: hidden;
}

#container {
  font-size: 24px;
  color: gray;
}

.spacer {
  text-align: center;
  font-size: 24px;
  color: white;
  min-height: 50px;
  padding: 10px;
  background-color: #0f0f5f;
}
.on-stage-title {
  margin-top: 50%;
}

.page1-scroll-height {
  height: 681px;
}

.page2-scroll-height {
  height: 1200px;
  transform: translate(50%, 50%);
}

.page3-scroll-height {
  height: 800px;
  transform: translate(50%, 50%);
}

.section {
  height: 30vh;
}

.page1-image {
  margin-top: -30%;
}

.page1-line {
  margin-top: -50%;
}

.page1-word {
  font-size: 20px;
  color: red;
  padding-left: 40%;
}

.page2-word {
  font-size: 20px;
  color: white;
  padding-left: 40%;
}

.home-mobile:nth-child(1) div:first-child {
  height: 550px !important;
}

.container-mobile {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.root-inner {
  height: 100vh;
  overflow: hidden;
}

.container-mobile .blob {
  margin-top: -25%;
  margin-left: -2%;
}

.top-line {
  margin-top: -21%;
}

.bottom-triangle {
  margin-top: -53%;
}

.texty1 {
  font-size: 52px;
  margin-top: -118%;
  color: white;
  margin-left: 22%;
}

.top-triangle2 {
  margin-top: -8%;
  margin-left: 54%;
}

.top-line2 {
  margin-top: -24%;
}

.container-mobile .blob2 {
  margin-top: -43%;
  margin-left: -2%;
}

.bottom-triangle2 {
  margin-top: -103%;
  margin-left: -44%;
}

.texty2 {
  font-size: 52px;
  margin-top: -124%;
  color: white;
  margin-left: 28%;
}

.container-mobile .blob3 {
  margin-top: -95%;
  margin-left: -32%;
  width: 138%;
}

.top-line3 {
  margin-top: -85%;
}

.texty3 {
  font-size: 52px;
  margin-top: -134%;
  color: white;
  margin-left: 27%;
  height: 100vh;
}

.bottom-triangle3 {
  margin-top: -25%;
  margin-left: 21%;
}

.top-triangle3 {
  margin-left: -13%;
}
