.language_icon {
  position: absolute;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100px;
}
.current-header .button_language_mobile {
  top: 40px;
}
.current-header .button_language_mobile.mobile-lang-item {
  top: 10px;
}
